import React from "react";

export default function ({deviceTypes, displayType, title, subtitle}) {
    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center',
            paddingTop: [deviceTypes.xs].includes(displayType) ? '2rem': '8rem',
        }}>
            <span style={{
                display: 'block',
                textAlign: 'center',
                fontWeight: 'bold',
                fontSize: '2.5rem',
                color: 'white',
            }}>
                {title}
                {/* {width} {displayType} {title} */}
            </span>
            {subtitle && (<span style={{
					display: 'block',
					textAlign: 'center',
					fontWeight: 'bold',
					fontSize: '1.5rem',
				}}>
					{subtitle}
				</span>)}
        </div>
    )
}