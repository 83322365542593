import React from 'react';
import { bool } from 'prop-types';
import { StyledMenu } from './Menu.styled';

const Menu = ({ open, items, logoLink, ...props }) => {
  
  const isHidden = open ? true : false;
  const tabIndex = isHidden ? 0 : -1;

  // const linkElement = ({icon, content}) => (
  //   <a href="/" tabIndex={tabIndex}></a>)

  return (
    <StyledMenu open={open} aria-hidden={!isHidden} {...props}
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        backgroundColor: 'gray',
        // height: '100vh', // 100vh = 1x full screen height, comment = fit content
        // width: '100%', // 100% covers page, commented covers part
        textAlign: 'left',
        padding: '2rem',
        position: 'absolute',
        top: '0px',
        left: '0px',
        borderRadius: '10px',
        borderRight: '2px solid white',
        zIndex: 99,
      }}
    >
      <div style={{ marginTop: '3rem' }}>
        {logoLink}
      </div>
      {items}
    </StyledMenu>
  )
}

Menu.propTypes = {
  open: bool.isRequired,
}

export default Menu;
