export const initUsers = 
	[
		{
			firstname: "John",
			lastname: "Doe",
			email: "johnDoe@gmail.com",
			state: "Wisconsin",
			city: "Stevens Point",
			password: "123password",
			id: "0001",
			signUpDate: "placeholder",
			isTherapist: false
		},
		{
			firstname: "Jane",
			lastname: "Doe",
			email: "janeDoe@gmail.com",
			state: "Wisconsin",
			city: "Stevens Point",
			password: "321password",
			id: "0002",
			signUpDate: "placeholder",
			isTherapist: false
		},
		{
			firstname: "Tommy",
			lastname: "Placeholder",
			email: "tp@gmail.com",
			state: "Wisconsin",
			city: "Stevens Point",
			password: "pass",
			id: "0005",
			signUpDate: "placeholder",
			isTherapist: false
		},
		{
			firstname: "Greg",
			lastname: "Generic",
			email: "greg@generic.com",
			state: "Wisconsin",
			city: "Stevens Point",
			password: "genericpass",
			id: "0003",
			signUpDate: "placeholder",
			isTherapist: true
		},
		{
			firstname: "Greta",
			lastname: "Generic",
			email: "email@generic.com",
			state: "Wisconsin",
			city: "Stevens Point",
			password: "123",
			id: "0004",
			signUpDate: "placeholder",
			isTherapist: true
		}
	];

// Relationships between hard coded users
export const initPatientToTherapist = 
	[
		{
			therapistId: "0003",
			patientId: "0002"
		},
		{
			therapistId: "0004",
			patientId: "0001"
		}
	]

// Emails associated with a therapist.
export const therapistEmailWhitelist = ["therapy.net", "fitness.com", "example.net"];