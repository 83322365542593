import React from 'react';

import testimonials from './data/infoRows';
import useWindowDimensions, { deviceTypes } from './hooks/useWindowDimensions';
import jumbotron from './img/jumbotron.gif';

function Home() {
	const { width, displayType } = useWindowDimensions();


	const imgBaseWidth = 600;
	const imgBaseHeight = 338;
	const wid = width / imgBaseWidth;
	const hght = wid * imgBaseHeight;
	const HomeImage = () => (<img src={jumbotron} alt="Home" style= {{ width: '100%' }}/>);

	function TitleSubtitle() {
		return (
			<div style={{
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'flex-start',
				alignItems: 'center',
				color: 'white',
			}}>
				<span style={{
					display: 'block',
					textAlign: 'center',
					fontWeight: 'bold',
					fontSize: '2.5rem',
				}}>
					<div>The workouts you know and love.</div>
					<div>Now with chickens. And monsters.</div>
					<div>Did we mention the lasers?</div>
				</span>
				<span style={{
					display: 'block',
					marginTop: '2rem',
					textAlign: 'center',
					fontWeight: 'bold',
					fontSize: '1.5rem',
				}}>
					<div>SIN Emerging Technologies brings holographic worlds to the gym near you.</div>
					<div>Stand on your toes to find an apple hiding the key to a mansion</div>
					<div>Squat to avoid laser attacks from ancient statues</div>
					<div>Hamstring stretch to feed a baby monster</div>
				</span>
			</div>
		)
	}

	function buildInfoRow(t, index) {
		const image = (<div className='col-xs-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4' >
			<img
				alt="Testimonial"
				src={t.img}
				style={{
					padding: '1rem',
					width: '100%'
				}}
			/>
		</div>);
		
		const desc = (<div className='col-xs-12 col-md-6 col-lg-8 col-xl-8 col-xxl-8'
				style={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'space-evenly',
					alignItems: 'center',
					padding: '3rem',
				}}
		>
			<span style={{
				display: 'block',
				textAlign: 'center',
				fontWeight: 'bold',
				fontSize: '2rem',
			}}>{t.title}</span>
			<span className="testimony-text">{t.txt}</span>
		</div>);

		const isEven = index%2 === 0;
		const key = t.title.replace(' ', '');

		if ([deviceTypes.xs, deviceTypes.sm].includes(displayType)) {
			return (<div className='row' key={key}>
				{image}
				{desc}
			</div>)
		}
		
		return (<div className='row' key={key}>
			{isEven ? image : undefined}
			{desc}
			{!isEven ? image : undefined}
		</div>)
	}

	function buildInfoCard(t, index) {
		return (<div key={index}
			style={{
				width: '100%',
			}}
		>
			<div
				style={{
					margin: '1rem 2rem',
					border: '2px solid black',
					backgroundColor: 'white'
				}}
			>
				{buildInfoRow(t, index)}
			</div>
		</div>);
	}

	return (
		<div className="container-fluid g-0"
			style={{
				margin: 0,
				padding: 0,
				backgroundColor: 'rgb(60,60,60)'
			}}
		>
			<div className="row g-0"
				style={{ paddingTop: '2em' }}
			>
				<div className="col-lg-4 g-0" style={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'flex-start',
					paddingLeft: '2em',
				}}>
					<HomeImage/>
				</div>
				<div className='col-lg-8'>
					<TitleSubtitle />
				</div>
			</div>
			<div className="row g-0">
				{testimonials.map(buildInfoCard)}
			</div>
		</div>
	);
}

export default Home;
