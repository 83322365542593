import React, { useState } from "react";
import Headline from "./components/Headline";
import iframeEmbedVideo from "./components/iframeEmbedVideo";
import images from './data/images';
import useWindowDimensions, { deviceTypes } from './hooks/useWindowDimensions';
	
function Prototype(props) {
	const { width, displayType } = useWindowDimensions();
	
	return(
		<div className="container-fluid g-0"
			style={{
				margin: 0,
				padding: 0,
				backgroundColor: 'rgb(60,60,60)'
			}}
		>
			<div className="row g-0">
				<div className='col' >
					<Headline deviceTypes displayType title='Prototype' />
				</div>
			</div>
			<div className="row g-0">
				<div style={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'flex-start',
					alignItems: 'center',
				}}>
					{iframeEmbedVideo({
						url: 'https://www.youtube.com/embed/p6TnWVJ3aUQ',
						aspectRatio: '16:9',	
					})}
					{images.map((image, idx) => (
						<img key={idx}
							src={image} alt="BigCo Inc. logo"
							style={{
								display: 'block',
								margin: '10%',
								maxWidth: '75%',
								border: '8px solid white',
							}}
						/>
					))}
				</div>
			</div>
		</div>
	);
}

export default Prototype;