import React from "react";

const paddingByAspectRatio = {// .embed-responsive-21by9
    '16:9': '56.25%', 	// .embed-responsive-16by9
    '4:3': '75%', 		// .embed-responsive-4by3
    '3:2': '66.66%', 
    '8:5': '62.5%', 
    '1:1': '100%', 		// .embed-responsive-1by1
}

function iframeEmbedVideo({ url, aspectRatio }) {
    return (<div // trouble getting bootstrap iframe classes to work, always 300x150
        // className="embed-responsive embed-responsive-16by9"
        style={{
            position: 'relative',
            overflow: 'hidden',
            width: '100%',
            paddingTop: paddingByAspectRatio[aspectRatio],
        }}
    >
        <iframe 
            // className="embed-responsive-item"
            frameBorder='0'
            style={{
                position: 'absolute',
                top: '0',
                left: '0',
                bottom: '0',
                right: '0',
                width: '100%',
                height: '100%',
            }}
            src={url}
            allow='autoplay; encrypted-media'
            allowFullScreen
            title='video'
        />
    </div>);
}

export default iframeEmbedVideo;