import styled from 'styled-components';

export const StyledMenu = styled.nav`
  transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(-100%)'};
  transition: transform 0.3s ease-in-out;

  a {
    transition: color 0.3s linear;

    &:hover {
      color: '#343078';
    }
  }
`;
