import React from "react";

/* This page is currently incomplete. For now, it merely shows the relationships between patients and therapists.
   The goal is to allow therapists to change or assign those relationships using a drop-down menu.*/
const Managepatients = ({ currentUser, users, patientToTherapist }) => {
	
	const patients = users.filter(user=> !user.isTherapist);
	const therapists = users.filter(user=> user.isTherapist);
	
	// A table containing all patients and their associated therapist.
	const PatientTable = () => {
		const table = [];
		const options = therapists.map((therapist) => 
			<option key={therapist.id} value={therapist.id}>{therapist.firstname} {therapist.lastname}</option>
		);
		options.push(<option key="none" value="none">none</option>);
		
		// Generate the table rows. Each should have a patient name and a drop-down containing all therapists.
		for(let i = 0; i < patients.length; i++) {
			const patientRelation = patientToTherapist.find(relation => relation.patientId === patients[i].id);
			var defaultTherapist = "none";
			if(patientRelation) {
				defaultTherapist = patientRelation.therapistId;
			}
			table.push(
				<tr style={{textAlign: "center"}}>
					<td>{users[i].firstname} {users[i].lastname}</td>
					<td>
						<select defaultValue={defaultTherapist}>
							{options}
						</select>
					</td>
				</tr>
			);
		}
		
		return (
			<table>
				<tbody>
					<tr>
						<th>Patient</th>
						<th>Assigned Therpaist</th>
					</tr>
					{table}
				</tbody>
			</table>
		);
	}
	
	// Returns the table, or an error message if the user is not logged in or is not a therapist.
	const ToReturn = () => {
		if(currentUser != null && currentUser.isTherapist) {
			return(
				<PatientTable/>
			);
		}
		return(
			<span>You are not authorized to view this page.</span>
		);
	}
	
	return(
		<div className="content">
			<div className="transbox">
				<ToReturn/>
			</div>
		</div>
	);
}

export default Managepatients;