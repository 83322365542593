import React from "react";
import { Link } from "react-router-dom";

function Footer() {
	const sections = [
		{
            title: 'Links',
            content: [
                {
                    path: '/prototype',
                    title: 'Tech'
                },
                {
                    path: '/aboutus',
                    title: 'About Us'
                },
                {
                    path: '/executivesummary',
                    title: 'Summary'
                },
                {
                    path: '/contact',
                    title: 'Contact',
                },
                // {
                //     path: '/login',
                //     title: 'Sign Up / Login'
                // },
            ]
        },
        // {
        //     title: 'Services',
        //     content: [
        //         {
        //             path: '/bodytrackinganalysis',
        //             title: 'Body Tracking Analysis',
        //         },
        //         {
        //             path: '/arsoftware',
        //             title: 'Augmented Reality Software',
        //         },
        //     ],
        // },
        {
            title: 'Social Media',
            content: [
                {
                    path: '/facebook',
                    title: 'Facebook',
                    url: 'https://www.facebook.com/SinEmergingTechnologies-211763910857639'
                },
                {
                    path: '/instagram',
                    title: 'Instagram',
                    url: 'https://www.instagram.com/SinEmergingTechnologies'
                },
                {
                    path: '/twitter',
                    title: 'Twitter',
                    url: 'https://www.twitter.com/SinEmergingTech'
                },
                {
                    path: '/youtube',
                    title: 'Youtube',
                    url: 'https://www.youtube.com/channel/UC4O3jPWglS9FEq91VKqd4bA'
                },
            ],
        },
		// <ConditionalRouting/>
	];

    function buildRouteLink(item, index, linkStyle) {
        return (
            <Link to={item.path} key={index} className="navItemHover" 
                style={linkStyle}
            >
                {item.title}
            </Link>
        )
    }

    function buildWebLink(item, index, linkStyle) {
        return (
            <a href={item.url} key={index} className="navItemHover" 
                style={linkStyle}
            >
                {item.title}
            </a>
        );
    }

    function buildContentList(content) {
        if (!content) return;

        const linkStyle = {
            textAlign: 'left',
            margin: '0.25rem',
            padding: '0 1rem',
            fontSize: '1rem',
            color: 'white',
            textDecoration: 'none',
            fontFamily: 'IBM Plex Sans, sans-serif',
        };

        return content.map((item, index) => item.url
            ? buildWebLink(item, index, linkStyle)
            : buildRouteLink(item, index, linkStyle)
        )
    }

	function createFooterEntry({ title, content }) {
        return (
            <div key={title} className='navItemHover col-sm-2'
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    textDecoration: 'none',
                    fontSize: '1.5rem',
                    padding: '0 1rem',
                    textAlign: 'center',
                    fontFamily: 'IBM Plex Sans, sans-serif',
                    color: 'white',
                }}
            >
                <div style={{ borderBottom: '2px solid white' }}>{title}</div>
                {buildContentList(content)}
            </div>
        );
    }

    const items = sections.filter(item => item.path !== '/login').map(createFooterEntry)

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: 'rgb(40,40,40)',
            color: 'white',
            paddingTop: '1rem',
            paddingBottom: '1rem',
        }}>
            <h1 style={{
                textAlign: "center",
            }}>
                SIN Emerging Technologies
            </h1>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                flexWrap: 'wrap',
            }}>
                {items}
            </div>
        </div>
    );
};

export default Footer;