/*React imports*/
import React, { useState, useRef } from "react";
import ReactDOM from "react-dom";
import './css/nav-item-hover.css';

import { initUsers, initPatientToTherapist, therapistEmailWhitelist } from "./data/UserData"

/*routing imports*/
import {
  BrowserRouter as Router,
  Route,
  Link,
  Switch,
} from "react-router-dom";
import Home from "./Home";
import Summary from "./Summary";
// import LogIn from "./LogIn";
// import Signup from "./Signup"
import Prototype from "./Prototype"
import Meetteam from "./Meetteam"
import Messages from "./Messages"
import Managepatients from "./Managepatients"
import Embed from "./components/Embed"
import Footer from "./Footer"
import Header from "./Header"
import Contact from "./Contact"

import 'bootstrap/dist/css/bootstrap.min.css';
import ClipboardJS from 'clipboard';

function App() {
	
	//State hooks related to user management.
	const[user, setUser] = useState(null);
	const[users, setUsers] = useState(initUsers);
	const[subscribed, setSubscribed] = useState([]);
	const[patientToTherapist, setPatientToTherapist] = useState(initPatientToTherapist);
	

	// Functions to manage state
	const signUpNewUser = (user) => {
		setUsers([...users,user]);
	}
	
	const setCurrentUser = (user) => {
		console.log("attempting login for user:")
		console.log(user)
		setUser(user);
	}
	
	const addNewSubscribed = (newSubscribed) => {
		setSubscribed([...subscribed,newSubscribed]);
	}

	// Dynamic navigation
	// User signed in?
	const ConditionalRouting = () => {
		if(user != null){
			return (
				<>
					<Link to="/messages">Messages</Link>
					<span id="navFloatLeft">Welcome {user.firstname}</span>
					<Link onClick={() => setUser(null)} to="/" id="navFloatLeft">Log Out</Link>
					{user.isTherapist &&
						<Link to="/manage">Manage Patients</Link>
					}
				</>
			);
		}// User not signed in
		return (
			<>
				<Link id="navFloatLeft" to="/login">Login</Link>
				<Link id="navFloatLeft" to="/signup">Sign Up</Link>
			</>
		);
	}

	const clipboard = new ClipboardJS('.clipboard-btn');

	clipboard.on('success', function (e) {
		console.info('Clipboard copy success!');
		// console.info('Action:', e.action);
		// console.info('Text:', e.text);
		// console.info('Trigger:', e.trigger);
		
		e.clearSelection();
	});
	
	clipboard.on('error', function (e) {
		console.info('Clipboard copy error :-(');
		console.error('Action:', e.action);
		console.error('Trigger:', e.trigger);
	});

	const headerSpacingElement = (<div style={{ height: '4.5em', backgroundColor: 'rgb(40,40,40)' }} />)

	return (
		<div style={{  }}>
			{headerSpacingElement}
			<Router>
				<Header />
				<Switch>
					<Route exact path="/">
						<Home />
					</Route>
					<Route path="/prototype">
						<Prototype />
					</Route>
					<Route path="/aboutus">
						<Meetteam />
					</Route>
					<Route path="/executivesummary">
						<Summary setNewSubscribed={addNewSubscribed} />
					</Route>
					<Route path="/contact">
						<Contact />
					</Route>
					{/* <Route path="/signup">
						<Signup signUpNewUser={signUpNewUser} whitelist={therapistEmailWhitelist} />
					</Route> */}
					{/* <Route path="/login">
						<LogIn setLoggedInUser={setCurrentUser} users={users} />
					</Route> */}
					<Route path="/messages">
						<Messages currentUser={users[2]} users={users} />
					</Route>
					<Route path="/manage">
						<Managepatients currentUser={user} users={users} patientToTherapist={patientToTherapist} />
					</Route>
					<Route exact path="/datavis">
						<Embed/>
					</Route>
				</Switch>
				<Footer />
			</Router>
		</div>
	);
}

ReactDOM.render(<App />, document.getElementById("root"));