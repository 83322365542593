import React, { useState, useRef } from "react";
import FocusLock from 'react-focus-lock';
import { Link } from "react-router-dom";
import useWindowDimensions, { deviceTypes } from './hooks/useWindowDimensions';
import Burger from './components/Burger';
import Menu from './components/Menu';
import useOnClickOutside from './hooks/useOnClickOutside';
import logoVert from './img/logo-vert.png';
import logoHoriz from './img/logo-horiz.png';

function Header() {
	const [open, setOpen] = useState(false);
	const menuId = 'main-menu';

	const menuItems = [
		{
			path: '/prototype',
			title: 'Tech'
		},
		{
			path: '/aboutus',
			title: 'About Us'
		},
		{
			path: '/executivesummary',
			title: 'Summary'
		},
		{
			path: '/contact',
			title: 'Contact',
		},
		{
			path: '/datavis',
			title: 'Data Vis',
		},
		// {
		// 	path: '/login',
		// 	title: 'Sign Up / Login'
		// },
		// <ConditionalRouting/>
	];

	const node = useRef();
	useOnClickOutside(node, () => setOpen(false));

	const createHeaderLink = ({ path, icon, title }) => (<Link to={path} key={path} style={{ textDecoration: 'none' }}>
		<div className="navItemHover" onClick={() => setOpen(false)}
			style={{
				fontSize: '1.5rem',
				textTransform: 'uppercase',
				padding: '0 1rem',
				fontWeight: 'bold',
				fontSize: '1.5rem',
				textAlign: 'center',
				fontFamily: 'IBM Plex Sans, sans-serif',
				color: 'white',
			}}
		>
			<span aria-hidden="true">{icon}</span>
			{title}	
		</div>
	</Link>)

	function useHamburger(logoLink) {
		return (
			<div ref={node}>
				<FocusLock disabled={!open}>
					<Burger open={open} setOpen={setOpen} aria-controls={menuId} />
					<Menu open={open} setOpen={setOpen} id={menuId} items={menuItems.map(createHeaderLink)} logoLink={logoLink} />
				</FocusLock>
		  </div>
		)
	}

	function useNavbar(logoLink) {
		return (
			<nav style={{
				position: 'absolute',
				top: 0,
				left: 0,
				display: 'flex',
				justifyContent: 'flex-end',
				alignItems: 'center',
				paddingRight: '1rem',
				width: '100%',
				height: '2.5em',

				color: 'white',
                backgroundColor: 'rgb(40,40,40)',
				fontSize: '1.75rem',
			}}>
				{logoLink}
				<div style={{
					display: 'flex',
					flexGrow: 1,
				}} />
				{menuItems.map(createHeaderLink)}
			</nav>)
		}

	const { displayType, width, height } = useWindowDimensions();
    console.log(`${displayType} ${width} ${height}`);

	const logoLink = {
        vert: (
			<Link to='/' style={{
                    textDecoration: 'none',
                }}
				onClick={() => setOpen(false)}
			>
                <img src={logoVert} className='logo' style={{
                    width: '12rem',
                    height: '8rem',
                    borderRadius: '10px',
                }} />
            </Link>),
        horiz: (
			<Link to='/' onClick={() => setOpen(false)}>
                <img src={logoHoriz} className='logo' style={{
                    width: '16rem',
                    height: '3rem',
                    margin: '0.5rem',
                    borderRadius: '10px',
                }} />
            </Link>)
    };

    function getLogo(logoObj, currentDisplayType) {
        const orientation = [deviceTypes.xs].includes(currentDisplayType) ? 'vert' : 'horiz';
        return logoObj[orientation];
    }

	const ham = useHamburger(getLogo(logoLink, displayType));
	const nav = useNavbar(getLogo(logoLink, displayType));

    return [deviceTypes.xs, deviceTypes.sm].includes(displayType) ? ham : nav;
}

export default Header;