import profilePicTodd from '../img/team/profilePicTodd.jpg';
import profilePicDan from '../img/team/profilePicDan.jpg';
import profilePicSam from '../img/team/profilePicSam.jpg';

export default [
    {
        name: "Todd A. Sinclair",
        title: "Founder & CEO",
        img: profilePicTodd,
        txt: [
            `Todd has always loved playing sports, but has struggled to enjoy exercise.
            The immersion of playing a sport hides the benefits of exercise and makes it fun and enjoyable for him.
            Sports come with injury, however, and Todd was prescribed physical therapy to speed his recovery.
            In the same manner, doing repetitions of stretches felt like work, so Todd gamified the actions to distract himself.`,
            
            `Todd has also followed the tech world, and read about Microsoft's Hololens headset in a 2017 Gizmag article.
            After reading more about augmented reality, the "a-ha" moment clicked.
            He realized that an augmented-reality game could be created to make exercise and stretching fun, while distracting the player from the boring repetitions.
            Todd applied for a Microsoft competition and won an order to buy a Hololens, and utilized Marquette's capstone teams to figure out building software for it.`,

            `Todd is the CEO and started work on the project in 2017.
            He brings 20 years of experience in golf coaching and experience running a previous start up.
            He met Dan via LinkedIn early in 2018 while looking for a software developer, and the two have been working together remotely ever since.
            In his previous startup, Todd took an idea from vision to prototype, using university resources to build a functional prototype and circuit board.
            He recruited skilled teammates for the team using his personal network and put together a business plan, using it to obtain upfront financing for a feature and design patent.
            The startup folded when financial issues arose and deliverables went unmet.
            Todd feels comfortable taking our product through the research and development phases, and we’re seeking an experienced CEO to take it to market.`
        ]
    },
    {
        name: "Dan Bitter",
        title: "Co-Founder & CTO",
        img: profilePicDan,
        txt: [
            `Dan came into the company in April 2018, and has led the development efforts with Angelo's advising.
            They realized additional tech talent was needed, and brought Will onboard in late 2019.
            A proof of concept was completed in late 2020, and work is underway to create fun games people will enjoy.`,
            
            `Dan has 7 years’ experience in various software roles, and serves as CTO.
            This is his first startup and he loves the opportunity to build something new.
            He handles the website, backend server, and camera software, and is excited to architect a personal project.`
        ],
},
{
    name: "Sam Altman",
    title: "CFO",
    img: profilePicSam,
    txt: ["Samuel brings a decade of accounting experience and the resources of Full Cycle CFO, Ltd to handle our CFO needs."]
    }
];