import React from "react";
import useWindowDimensions, { deviceTypes } from './hooks/useWindowDimensions';
import './css/linkstyle.css';
import googlemaps from './img/icons/googleMaps.png';
import directionsBlue from './img/icons/directionsBlue.png';
import copy from './img/icons/copy.png';
import emailIcon from './img/icons/emailIcon.png';

const Contact = () => {
	const { width, displayType } = useWindowDimensions();

    const emails = [
        {
            name: 'Todd Sinclair, CEO',
            email: 'Todd@SinEmergingTechnologies.com'
        },
        {
            name: 'Dan Bitter, CTO',
            email: 'Dan@SinEmergingTechnologies.com'
        },
        {
            name: 'Sam Altman, CFO',
            email: 'Sam@FullCycleCFO.com'
        },
    ];

    function makeIcon({ link, icon, title, incStyle }) {
        const content = (
            <div style={{
                display: 'flex',
                alignItems: 'center',
            }}>
                <img src={icon} className='' style={{
                    width: '4rem',
                    height: '4rem',
                    borderRadius: '4px',
                    marginRight: '1em',
                    ...incStyle
                }} />
                <div>{title}</div>
            </div>
        );

        if (link) {
            return (
            <a href={link}>
                {content}
            </a>
            )
        }
        return content;
    }

    const AddressMap = () => {
        return (
            <div className="google-map-code"
                style={{
                    display: 'flex',
                    alignItems: 'stretch',
                    width: '100%',
                    height: '30em'
                }}
            >
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d10045.952083323422!2d-81.38871378922961!3d30.274449189584427!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88e449b5f9957617%3A0x69553de381abb945!2sStarbucks!5e0!3m2!1sen!2sus!4v1630781227370!5m2!1sen!2sus"
                    style={{
                        display: 'flex',
                        flex: 1,
                        border: 0,
                    }}
                    allowfullscreen=""
                    loading="lazy">
                </iframe>
            </div>
        );
    }

    const spacerDiv = (<div style={{ margin: '1em' }} />);

	return(
		<div className="container-fluid g-0"
			style={{
				margin: 0,
				padding: 0,
				backgroundColor: 'rgb(60,60,60)',
				color: 'white',
			}}
		>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'center'
            }}>
                <div style={{ fontSize: '2em' }}>Get in touch</div>
                <div style={{ fontSize: '1.25em' }}>We'd love to chat!</div>
                <div style={{ fontSize: '1.25em' }}>If you're interested, having difficulty, or just bored!</div>
            </div>
            <div className='row g-0' style={{
                padding: '2rem',
            }}>
                <div className='col-sm-3'>
                    <h4>Text / Call / Voicemail</h4>
                    <div>Phone number coming soon!</div>
                </div>
                <div className='col-sm-3'>
                    <h4>Chat</h4>
                    <div>Chat feature coming soon!</div>
                </div>
                <div className='col-sm-3'>
                    <h4>Email</h4>
                    <div>
                        <div>Tap to launch your email app</div>
                        <div>Long-press to copy the email address</div>
                    </div>
                    {emails.map(({name, email}) => {
                        return makeIcon({
                                link: `mailto:${email}`,
                                icon: emailIcon,
                                title: name,
                                incStyle: {
                                    height: '3rem',
                                    width: '4rem',
                                    background: 'white',
                                    margin: '0.5rem',
                                    padding: '0.5rem',
                                }
                            });
                    })}
                </div>
                <div className='col-sm-3'>
                    <h4>In-Person</h4>
                    <div>Coffee's on us - let's chat!</div>
                    <a href='https://goo.gl/maps/cnTaRveBUpwMTmxw8'>
                        <div>Starbucks</div>
                        <div>975 3rd St S</div>
                        <div>Jacksonville Beach, FL 32250</div>
                    </a>
                    {spacerDiv}
                    <a className='clipboard-btn'
                        data-clipboard-text='975 3rd St S, Jacksonville Beach, FL 32250'
                    >
                        {makeIcon({
                            link: undefined,
                            icon: copy,
                            title: 'Copy the address!',
                            incStyle: {
                                background: 'white',
                            }
                        })}
                    </a>
                    {spacerDiv}
                    {makeIcon({
                        // 'http://maps.apple.com/?daddr=Starbucks+975+3rd+St+S+Jacksonville+Beach,+FL+32250&dirflg=d&t=m'
                        link: 'https://www.google.com/maps/dir//Starbucks+975+3rd+St+S+Jacksonville+Beach,+FL+32250/@30.279227,-81.38965,15z/data=!4m5!4m4!1m0!1m2!1m1!1s0x88e449b5f9957617:0x69553de381abb945',
                        icon: directionsBlue,
                        title: 'Launch directions to Starbucks in Google Maps!',
                    })}
                    {spacerDiv}
                    {makeIcon({
                        // 'http://maps.apple.com/?address=Starbucks+975+3rd+St+S+Jacksonville+Beach,+FL+32250&t=m'
                        link: 'https://www.google.com/maps/search/?api=1&query=Starbucks+975+3rd+St+S+Jacksonville+Beach,+FL+32250/',
                        icon: googlemaps,
                        title: 'Open this Starbucks in Google Maps!',
                    })}
                </div>
            </div>
            {spacerDiv}
            <div className='row g-0'>{AddressMap()}</div>
            <div></div>
		</div>
	);
}

export default Contact;