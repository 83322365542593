import azurekinect from '../img/azurekinect/azurekinect.webp'
import azurekinectskeleton from '../img/azurekinect/azurekinect-skeleton.jpg'
import azurekinectexercise from '../img/azurekinect/azuerkinect-exercise1.jpg'
import hololenshologram from '../img/hololens/hololens-hologram.webp';
import hololenshologram2 from '../img/hololens/hololens-hologram2.jpg';
import hololensvideocall from '../img/hololens/hololens-videocall.jpg';

export default [
    {
        img: Object.values({azurekinect}),
        txt: "This camera uses artificial intelligence to recognize your joints and near-infared light to measure their coordinates. It intelligently provides skeletal data 20-30 times per second and can be synced with additional cameras for pinpoint accuracy.",
        title: "Foundation 1: The Azure Kinect camera"
    },
    {
        img: Object.values({azurekinectskeleton}),
        txt: "We focus on specific joints relevant to the current exercise and build a history of exercise sessions. Repetitions can be compared for consistency, performance, and optimization.",
        title: "Interpreting the data"
    },
    {
        img: Object.values({azurekinectexercise}),
        txt: "Exercises are compared against the your historical sessions for consistency and progress, and also against an ideal standard to keep the goal in sight. This uncovers signs of injury and fatigue, which can drive adjustments to the exercise plan. You also see measurable, visual indicators of your progress over time, serving to reward and motivate your efforts.",
        title: "Measurable, actionable data"
    },
    {
        img: Object.values({hololenshologram2}),
        txt: "The HoloLens places digital holograms in your field of view, complementing the physical space around you. The incredible experience this provides is difficult to understate! As creatures of habit, most of us take our regular enviornments for granted. This application of dynamic content drives new ways to engage with the familiar, bringing unfound enjoyment as the brain considers new possibilities.",
        title: "Foundation 2: The HoloLens headset" // Enhancing their physical space
    },
    {
        img: Object.values({hololenshologram}),
        txt: "Hand tracking allows you to touch, tap, and grab holograms with your bare hands - no controllers required.",
        title: "Unleash your inner Iron Man!"
    },
    {
        img: Object.values({hololensvideocall}),
        txt: "Personal trainers and fitness professionals will be with you! They can work 1:1 with new clients, conduct virtual group sessions, and pop-in with advanced users as-needed. They'll evaluate your form, your ability to conduct the exercise, and answer questions along the way. They can adapt the individual amount of attention for you and conduct larger or smaller groups of clients as they choose.",
        title: "Real-time feedback"
    },
]