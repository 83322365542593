import prototype1 from "../img/Arm-Raise-Ball.jpg";
import prototype2 from "../img/Exercise-Screen-with-Hand.jpg";
import prototype3 from "../img/Shoulder-Raise-Pic-Reps-Complete.jpg";

// Slideshow images 
export default [
        Object.values({prototype1}), 
        Object.values({prototype2}), 
        Object.values({prototype3})
    ];
