import React from "react";
import Headline from "./components/Headline";
import team from './data/team';
import useWindowDimensions, { deviceTypes } from './hooks/useWindowDimensions';

const Meetteam = () => {
	const { width, displayType } = useWindowDimensions();

	return(
		<div className="container-fluid g-0"
			style={{
				margin: 0,
				padding: 0,
				backgroundColor: 'rgb(60,60,60)',
				color: 'white',
			}}
		>
			<Headline deviceTypes displayType title='Meet the team!' />
			<div className="row g-0">
				{team.map(person => (<TeamMember teammate={person} displayType={displayType} />))}
			</div>
		</div>
	);
}

const headerAndSubheader = ({name, title}) => (
	<div style={{
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'flex-start',
	}}>
		<div style={{
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'flex-start',
			alignItems: 'center',
			margin: '2rem',
		}}>
			<div style={{
					display: 'block',
					textAlign: 'center',
					fontWeight: 'bold',
					fontSize: '2.5rem',
				}}>{name}</div>
			<div style={{
					display: 'block',
					textAlign: 'center',
					fontWeight: 'bold',
					fontSize: '1.5rem',
				}}>{title}</div>
		</div>
	</div>
)

const TeamMemberAsRow = ({name, title, personImage: img, txt}) => (
	<>
		<div className="col-xs-12 col-sm-6 col-lg-6 g-0" style={{
			marginTop: '2rem',
			marginBottom: '2rem',
		}}>
			{img}
		</div>
		<div className="col-xs-12 col-sm-6 col-lg-6 g-0" style={{
			paddingRight: '2rem'
		}}>
			{headerAndSubheader({name, title})}
			<div>{txt.map(paragraph => (<p>{paragraph}</p>))}</div>
		</div>
	</>
);

const TeamMemberAsColumn = ({name, title, personImage: img, txt}) => (
		<div className="col-xl-4 g-0">
			<div style={{
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'flex-start',
				alignItems: 'center',
			}}>
				{headerAndSubheader({name, title})}
				<div>{img}</div>
			</div>
			<div style={{
				margin: '2rem'
			}}>
				{txt.map(paragraph => (<p>{paragraph}</p>))}
			</div>
		</div>
);

const displayOptions = {
	row: TeamMemberAsRow,
	col: TeamMemberAsColumn
}

function TeamMember ({ teammate, displayType }) {
	const { name, title, img, txt } = teammate;
	const bootstrapStyle = [deviceTypes.xl, deviceTypes.xxl].includes(displayType) ? 'col' : 'row'
	
	const personImage = (<img src={img} alt="Team Member"
		style={{
			display: 'block',
			margin: 'auto',
			maxWidth: '75%',
			border: '8px solid white',
		}}/>)

	return displayOptions[bootstrapStyle]({name, title, personImage, txt});
}

export default Meetteam;