import { useState, useEffect } from 'react';

export const deviceTypes = {
  xs: 'xs',
  sm: 'sm',
  md: 'md',
  lg: 'lg',
  xl: 'xl',
  xxl: 'xxl',
}
export const deviceWidths = {
  // xs: 576,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400,
}

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;

  let displayType = deviceTypes.md;

    if (width < deviceWidths.sm) {
      displayType = deviceTypes.xs;
      // console.log('deviceTypes.xs')
    } else if (deviceWidths.sm < width && width < deviceWidths.md) {
      displayType = deviceTypes.sm;
      // console.log('deviceTypes.sm')
    } else if (deviceWidths.md < width && width < deviceWidths.lg) {
      displayType = deviceTypes.md;
      // console.log('deviceTypes.md')
    } else if (deviceWidths.lg < width && width < deviceWidths.xl) {
      displayType = deviceTypes.lg;
      // console.log('deviceTypes.lg')
    } else if (deviceWidths.xl < width && width < deviceWidths.xxl) {
      displayType = deviceTypes.xl;
      // console.log('deviceTypes.xl')
    } else if (deviceWidths.xxl < width) {
      displayType = deviceTypes.xxl;
      // console.log('deviceTypes.xxl')
    } else {
      displayType = deviceTypes.sm;
      // console.log('deviceTypes.sm')
    }

	// console.log(`${width} : ${displayType}`);
      
  return {
    width,
    height,
    displayType
  };
}


export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());

    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}