export default [
    {
        title: 'Vision',
        paragraphs: [
            `We believe in performance with a purpose. Sin Emerging Technologies is a tech company 
            with the mission to empower every person to stay fit and be healthy. We strive to make fitness fun 
            by entertaining, informing, and inspiring through our platform.`
        ],
    },
    {
        title: 'Problem',
        paragraphs: [
            `The fitness industry currently represents $94 billion dollars, with digital fitness representing 
            the largest growth in the space. With digital slated to make up as much as 30% of the industry, 
            there is a massive opportunity to bridge the gap between market demand for more digital fitness 
            experiences and existing gym infrastructure. With member retention rates being the key driving 
            force of bottom-line revenue for gyms, products that improve retention and compliance have 
            rapid acceptance in the space.`
        ],
    },
    {
        title: 'Company Description',
        paragraphs: [
            `SIN Emerging Technologies is revolutionizing the modern gym experience through augmented reality. We
            have developed an immersive user experience that allows gym owners to gamify workouts and
            transform their gyms into an adaptive user environment that can fit the needs of individual customers.`,
            
            `Augmented reality allows users to interact with the existing gym infrastructure in a new way, rather than
            virtual reality, which creates an entirely digital environment with little physical interaction. SIN platform
            users can see and interact with the real world with an enhanced sensory experience, improving exercise
            program compliance and allowing real time feedback on form. Users are able to turn their workouts into
            an exciting game that challenges them to push their boundaries and focus on incremental
            improvements. Trainers can access user data and leverage that to create custom experiences for their
            clients with the SIN platform.`
        ],
    },
    {
        title: 'Industry analysis',
        paragraphs: [
            `The $94 billion dollar fitness industry is growing at a 32.6% CAGR, with digital growing at 10 times that rate. 
            We find that the gym equipment sector is growing as well, with connected products moving from 2.9% of equipment sales
            to 7.5% last year.`
        ],
    },
    {
        title: 'Market Analysis',
        paragraphs: [
            `The mind body index rates found that virtual fitness experiences appealed to all age categories below
            55+. To put this in perspective, it accounts for 78% of all gym-goers in the United States. AI is one of the
            fastest growing sectors in digital fitness at a 43.39% CAGR, and could threaten to replace trainers. This
            has caused gyms and trainers to resist adopting AI products, which we see as an opportunity to bridge
            the gap between enhanced data and fitness professionals.`
        ],
    },
    {
        title: 'Marketing Plan',
        paragraphs: [
            `The SIN platform is intended to be a B2B product sold directly to gyms and fitness studios. Our initial
            outreach will start on a state-by-state basis combining social media outreach to create awareness, as
            well as person-to-person sales to engage early adopters. As awareness grows, we will expand
            geographically through additional sales force and public outreach.`
        ],
    },
    {
        title: 'Management Team',
        paragraphs: [
            `The SIN Team is composed of Todd Sinclair and Dan Bitter with a team of subject matter experts. Todd,
            the CEO, started the project and has experience in the fitness industry as well as experience running a
            prior startup. Dan, the CTO, has experience in software and development. The two are backed by
            seasoned software developers, accounting, and legal teams.`
        ],
    },
    {
        title: 'Development Plan',
        paragraphs: [
            `Initial product development is complete with patents filed and is moving into beta testing. 
            The technology will soon be ready to move into distribution and sales.`
        ],
    },
    {
        title: 'Financial Projections',
        paragraphs: [
            `Based on an equipment leasing model with user subscriptions, 
            we expect to see an average of $5,400.00 per year in revenue from each unit. 
            Based on projections of initial and increasing units, we expect the following revenues:`
        ],
        data: {
            columns: ['', 'Units', 'Sales'],
            rows: [
                [
                    'Y2\u00A0\u00A0',
                    '100',
                    '$570,240.00'
                ],
                [
                    'Y3\u00A0\u00A0',
                    '300',
                    '$2,076,192.00'
                ],
                [
                    'Y4\u00A0\u00A0',
                    '700',
                    '$5,440,953.60'
                ],
                [
                    'Y5\u00A0\u00A0',
                    '1400',
                    '$11,912,762.88'
                ],
            ]
        }
    },
    {
        title: 'Funding',
        paragraphs: [
            `We are currently seeking $200,000 of funding to complete product development, 
            execute our marketing plan, build out our supply chain, 
            continue with the legal process for our patent, and complete commercialization.`
        ],
    },
    {
        title: 'Conclusion',
        paragraphs: [
            `The pandemic has accelerated early trends toward digital and remote solutions for personal fitness, 
            and consumers have a great variety of convenient options. Gyms that offer innovative and convenient 
            products will flourish as former gym members return, and our augmented reality kit will accelerate those directives.`,
            
            `Customers will be excited to experience a polished example of emerging technology, and their excitement 
            will increase with the immersive experience we integrate into their physical environment. The data 
            analysis we provide will offer new insight for improvements and give customers a visible history of the progress they’ve made. 
            Gyms will love the community that forms around the games, as members employ different strategies to 
            overcome obstacles and beat the games. This will drive member sign-ups and retention, which are both 
            key components of a gym’s long-term success.`
        ],
    },
];
