import React, {useState} from "react";
import {getCurrentDate} from "./data/utils.js";

const Messages = ({currentUser, users}) => {

	const [message, update_message] = useState('');

	//list of patients
	const SideBar = () => (
		<div className='patientList'>
			<h1>Patients:</h1>
			<br/>
			<ul>
				<li id='patientListElement'>
					list element
				</li>
			</ul>
		</div>
	)
	
	// Container for direct messages
	const MessageDisplay = () => (
		<>
			<div className='recievedMessage' id='directMessage'>
				{RecievedMessage()}
			</div>
			<hr className='messageDivider'></hr>
			<div className='sentMessage' id='directMessage'>
				{SentMessage()}
			</div>
		</>
	)
	
	const RecievedMessage = () => (
		<>
			<h5>From: {'sender'}</h5>
			<br/>
			<p>Can I reschedule our next session?</p><br/>
			<h6>Recieved: {getCurrentDate()}</h6>
		</>
	)

	//message sent by current user
	const SentMessage = () => (
		<>
			<h5>To: {'recipient'}</h5>
			<br/>
			<p>Sure thing!</p><br/>
			<h6>Sent: {getCurrentDate()}</h6>
		</>
	)
	
	const messageInput = () => (
		<div className='messageInput'>
			<input 
				type="text" 
				name="newMessage" 
				placeholder="Enter your message here..." 
				value={message}
				onChange={(e)=>update_message(e.target.value)}/>
			<div className='messageButton'>
				{"Send"}
            </div>
		</div>
	)

	return (
		<div className="content">
			{SideBar()}
			<div className="messageBox">
				<div id='messageDisplay'>
				{MessageDisplay()}
				</div>
				{messageInput()}
			</div>
		</div>
	);
};


export default Messages;